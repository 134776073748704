@import 'assets/styles/_imports';

.profile-main {
	display: flex;
	flex-direction: column;

	&__avatar {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 16px;
	}

	&__avatar-image {
		width: 66px;
		height: 66px;
		border-radius: 50%;
		margin-bottom: 12px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include mobile-medium-min {
			width: 88px;
			height: 88px;
			margin-bottom: 10px;
		}
	}

	&__avatar-change {
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $primary;

		@include mobile-medium-min {
			padding: 5px;
			font-weight: 500;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: -0.23px;
		}
	}

	&__name {
		display: flex;
		flex-direction: column;
	}

	&__label {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimaryOpacity60;
		margin-bottom: 10px;
	}

	&__input {
		width: 100%;
		border: 1px solid $borderPrimaryColorOpacity20;
		border-radius: 8px;
		height: 48px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}

	&__input-text {
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $textPrimaryOpacity80;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__input-edit {
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		color: $primary;
	}
}
