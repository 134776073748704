@import 'assets/styles/_imports';

.header {
	$block: '.header';

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 44px;
	padding: 0 16px 0 8px;
	background: $black;
	border-bottom: 1px solid $borderPrimaryColorOpacity20;
	z-index: 5;

	&__title {
		font-size: 16px;
		line-height: 19px;
		font-weight: 500;
		color: $textThird;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__buttons {
		width: 100%;
		display: flex;
		align-items: center;
		margin-right: auto;
		flex-shrink: 0;
	}

	&__button {
		width: 36px;
		height: 36px;
		margin-right: 10px;
		font-size: 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		border-radius: 50%;
		transition: background $duration;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: $textPrimaryOpacity20;
		}

		&--collapse {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='2' viewBox='0 0 16 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='2' rx='1' fill='black' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
		}

		&--expand {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='6' viewBox='0 0 16 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='14' height='4' stroke='black' stroke-opacity='0.6' stroke-width='2'/%3E%3C/svg%3E%0A");
		}
	}

	&__noinet-wrapper {
		position: relative;
		margin-left: auto;
		margin-right: 8px;
		line-height: 0;

		& + #{$block}__slowmode-wrapper {
			margin-left: -2px;
		}
	}

	&__noinet-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 28px;
	}

	&__left {
		display: flex;
		align-items: center;
	}

	&__right {
		display: flex;
		align-items: center;
	}

	&__slowmode-wrapper {
		position: relative;
		margin-right: 2px;
		line-height: 0;
	}

	&__slowmode-btn {
		display: flex;
		width: 36px;
		height: 28px;

		svg {
			margin: auto;
		}
	}

	&__exit-btn {
		display: flex;
		width: 32px;
		height: 32px;

		svg {
			display: block;
			margin: auto;
		}
	}

	&__settings-wrapper {
		position: relative;
		margin-left: 8px;
	}

	&__settings {
		display: flex;
		align-items: center;
		height: 28px;
		padding: 0 11px;
		background-color: #4a4c4f;
		border-radius: 100px;

		svg {
			width: 20px;
			height: 20px;
		}
	}

	.tooltip {
		max-width: 300px;
	}
}
