.profile-screens {
	display: flex;
	flex-direction: column;
	height: 100%;

	&__title {
		color: $textPrimary;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		text-align: center;
		margin-bottom: 18px;

		@include mobile-medium-min {
			font-weight: 500;
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.38px;
			margin-bottom: 30px;
		}
	}
}
