@import 'assets/styles/imports';

.highlight {
	$block: '.highlight';

	width: 100%;

	&__container {
		width: 280px;
		border-radius: 9px;

		@include mobile-small {
			width: 262px;
		}
	}

	&__title,
	&__text {
		word-break: break-word;
	}

	&__text {
		margin-bottom: 15px;
		white-space: pre-wrap;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__href {
		display: block;
		width: 100%;
		padding: 8px 16px;
		text-align: center;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textSecond;
		background-color: $primary;
		border-radius: 6px;
		user-select: none;
	}

	&[class*='--template'] {

		#{$block}__title,
		#{$block}__text {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			color: $textPrimary;
			letter-spacing: -0.02em;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		#{$block}__title {
			-webkit-line-clamp: 2;
			max-width: 142px;
			font-weight: 700;
			font-size: 20px;
			line-height: 23px;
			flex-shrink: 0;
		}

		#{$block}__text {
			-webkit-line-clamp: 4;
			font-size: 13px;
			line-height: 15px;
		}
	}

	&[class*='--template_2'] {
		#{$block}__title {
			max-width: 248px;
			font-size: 34px;
			line-height: 40px;
		}
	}

	&[class*='--template_3'] {
		#{$block}__title {
			color: #000;
		}
	}

	&[class*='--template_6'],
	&[class*='--template_7'] {
		#{$block}__text {
			color: rgba(255, 255, 255, 0.6);
		}
	}

	&--template_1 {
		#{$block}__container {
			background: $backgroundSecond;
		}

		#{$block}__head {
			background-repeat: no-repeat;
			background-position: center;
		}

		#{$block}__head {
			display: flex;
			align-items: center;
			min-height: 99px;
			padding: 0 16px;
			border-radius: 9px;
			background-size: cover;
		}

		#{$block}__body {
			padding: 15px 16px;
		}
	}

	&--template_2 {
		#{$block}__container {
			padding: 20px 16px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		#{$block}__title {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--template_3 {
		#{$block}__container {
			background: $backgroundSecond;
		}

		#{$block}__head {
			display: flex;
			align-items: center;
			height: 113px;
			margin-bottom: -14px;
			padding: 0 16px;
			border-top-left-radius: 9px;
			border-top-right-radius: 9px;
			background-repeat: no-repeat;
			background-position: top left;
			background-size: cover;
		}

		#{$block}__body {
			padding: 15px 16px;
		}

		#{$block}__title {
			transform: translate(5px, -8px) rotate(-5deg);
		}
	}

	&--template_4 {
		#{$block}__container {
			background-repeat: no-repeat;
			background-position: center top;
		}

		#{$block}__container {
			background-size: cover;
		}

		#{$block}__head {
			display: flex;
			align-items: center;
			min-height: 106px;
			padding: 0 16px;
			border-radius: 9px;
			background-size: cover;
		}

		#{$block}__body {
			padding: 15px 16px;
		}

		#{$block}__title {
			position: relative;
			margin-bottom: -14px;
			padding-bottom: 4px;

			&:after {
				@include ps-elem;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 106px;
				height: 2px;
				background-color: $primary;
				border-radius: 2px;
			}
		}
	}

	&--template_5 {
		#{$block}__container {
			padding: 20px 52px 20px 16px;
			background-repeat: no-repeat;
			background-position: right -16px center;
			background-size: contain;
			background-color: $backgroundSecond;
		}

		#{$block}__title {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		#{$block}__text {
			padding-right: 42px;
		}
	}

	&--template_5,
	&--template_6 {
		#{$block}__container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 315px;
			min-height: 199px;

			@include mobile-small {
				width: 262px;
			}
		}
	}

	&--template_6,
	&--template_7 {
		text-align: center;

		#{$block}__container {
			padding-top: 16px;
			padding-bottom: 16px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-color: $backgroundSecond;
		}

		#{$block}__title {
			margin: 0 auto 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--template_6 {
		#{$block}__container {
			padding-left: 34px;
			padding-right: 34px;
		}
	}

	&--template_7 {
		#{$block}__container {
			width: 280px;
			min-height: 125px;
			padding-left: 20px;
			padding-right: 20px;

			@include mobile-small {
				width: 262px;
			}
		}
	}

	&--template_8 {
		#{$block}__container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 300px;
			min-height: 140px;
			padding: 20px 16px;
			background-color: $backgroundPrimary;

			#{$block}__title {
				max-width: 250px;
				margin-bottom: 8px;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: -0.23px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			#{$block}__text {
				margin-bottom: 8px;
				font-size: 15px;
				line-height: 20px;
				letter-spacing: -0.23px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			#{$block}__href {
				width: auto;
				min-width: 120px;
				margin-top: auto;
			}

			&--center {
				align-items: center;
				justify-content: center;

				#{$block}__title,
				#{$block}__text {
					text-align: center;
				}

				#{$block}__href {
					margin-top: 0;
				}

				#{$block}__title+#{$block}__href {
					margin-top: 8px;
				}
			}
		}
	}

	&--custom_1,
	&--custom_2 {
		#{$block}__container {
			display: flex;
			align-items: flex-end;
			position: relative;
			width: 300px;
			height: 238px;
			overflow: hidden;

			@include mobile-small {
				width: 262px;
			}
		}

		#{$block}__img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}

		#{$block}__body {
			width: 100%;
			padding: 10px 26px;
			z-index: 1;
		}

		#{$block}__title {
			max-width: 230px;
			margin-bottom: 10px;
			font-weight: 600;
			font-size: 17px;
			line-height: 23px;
			letter-spacing: -0.43px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			&:last-child {
				margin-bottom: 0;
			}
		}

		#{$block}__text {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			margin-bottom: 9px;
			font-weight: 400;
			font-size: 13px;
			line-height: 18px;
			letter-spacing: -0.078px;
			text-overflow: ellipsis;
			overflow: hidden;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--custom_1 {
		#{$block}__body {
			background: linear-gradient(180deg, rgba(29, 31, 35, 0) 0%, $backgroundSecond 55.73%);
		}
	}

	&--custom_2 {
		#{$block}__body {
			background: rgba(52, 52, 52, 0.7);
			border-radius: 9px;
		}
	}

	&--custom_3 {
		#{$block}__container {
			position: relative;
			width: 210px;
			padding: 10px;
			background: $backgroundSecond;
			overflow: hidden;
		}

		#{$block}__img {
			width: 100%;
			margin-bottom: 5px;
			padding-top: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			border-radius: 4px;
		}

		#{$block}__title,
		#{$block}__text {
			margin-bottom: 10px;
			text-overflow: ellipsis;
			overflow: hidden;

			&:last-child {
				margin-bottom: 0;
			}
		}

		#{$block}__title {
			max-width: 190px;
			font-weight: 600;
			font-size: 13px;
			line-height: 20px;
			white-space: nowrap;
		}

		#{$block}__text {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			margin-bottom: 10px;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.6);
		}
	}
	&--custom_4 {
		#{$block}__container {
			min-height: 120px;
			width: auto;
			max-width: 100%;
		}

		#{$block}__img {
			width: 100%;
			min-height: 120px;
			max-height: 300px;
			border-radius: 9px;
		}

		#{$block}__href{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			background-color: transparent;
			font-size: 0;
		}
	}
}
