@import 'assets/styles/_imports';

.btn {
	$block: '.btn';

	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 13px 20px;
	font-weight: 500;
	font-size: 17px;
	line-height: 22px;
	color: black;
	background: $primary;
	border: none;
	border-radius: 8px;
	transition: color $duration, background $duration;
	user-select: none;
	outline: none;

	&--dark {
		background-color: $backgroundPrimary;
		color: $textPrimary;
	}

	&[disabled] {
		background-color: $textPrimaryOpacity20;
		color: $textPrimaryOpacity20;
	}

	&--poll {
		width: 100%;
		font-weight: 400;
		background-color: $primary;
	}

	&--poll[disabled],
	&--poll#{&}--disabled {
		background-color: $btndisabled;
		color: $textSecond;

		#{$block}__right-icon {
			pointer-events: none;

			path {
				fill: $textSecond;
			}
		}
	}

	&__right-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}

	&--sm {
		font-size: 12px;
		line-height: 16px;
		padding: 8px;
		border-radius: 4px;
	}
}
