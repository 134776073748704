@import 'assets/styles/imports';

.reactions {
	max-width: 240px;
	margin-top: 4px;

	&__items {
		display: flex;
		flex-wrap: wrap;
		margin-right: -5px;
		margin-bottom: -3px;
	}

	&__item {
		margin-right: 5px;
		margin-bottom: 3px;
	}

	&__item-btn {
		display: flex;
		align-items: center;
		height: 22px;
		padding: 1px 6px 1px 4px;
		font-size: 10px;
		line-height: 12px;
		color: $textThird;
		font-feature-settings: 'tnum';
		font-variant-numeric: tabular-nums;
		background: $backgroundInverseOpacity10;
		border-radius: 20px;
		border: none;
		transition: background $duration;
		user-select: none;
		cursor: pointer;

		* {
			pointer-events: none;
		}

		&--selected {
			color: $textPrimary;
			background: $textThird;
		}
	}

	&__item-img {
		width: 20px;
		height: 20px;
		margin-right: 2px;
		flex-shrink: 0;
		object-fit: contain;
	}
}
