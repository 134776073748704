@import 'assets/styles/_imports';

.users {
	$block: '.users';

	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	// position: fixed;
	// top: 8px;
	// right: 16px;
	transition: background $duration, box-shadow $duration;
	border-radius: 16px;
	user-select: none;

	&--have-speakers {
		padding: 2px 4px;
		background-color: $backgroundSecond;
		box-shadow: 0px 2.80442px 2.80442px rgba(0, 0, 0, 0.25);

		#{$block}__counter {
			height: 24px;
			border-color: transparent;
		}
	}

	&__counter {
		display: flex;
		align-items: center;
		height: 28px;
		padding: 0 10px;
		border-radius: 14px;
		background-color: #4a4c4f;

		svg {
			width: 17px;
			height: 11px;
			margin-right: 4px;
		}
	}

	&__counter-number {
		font-size: 13px;
		line-height: 17px;
		color: $textPrimaryOpacity60;
		font-variant-numeric: tabular-nums;
	}

	&__click-area {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
		z-index: 1;
	}

	&__list {
		display: flex;
		flex-direction: row;
		margin-right: 1px;
	}

	&__list-item {
		position: relative;
		margin-right: 2px;
		border-radius: 50%;
		padding: 1px;
		border: 1px solid transparent;

		&--active {
			border-color: $primary;
		}
	}

	&__list-item-img {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}
}