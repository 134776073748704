@import 'assets/styles/_imports';

.toasts {
	position: absolute;
	left: 16px;
	bottom: 67px;
	width: calc(100vw - 32px);
	z-index: 20000;

	@include mobile-medium-min {
		left: 20px;
		width: calc(100% - 40px);
	}
}

.toast {
	$block: '.toast';

	display: flex;
	align-items: center;
	width: 100%;
	margin-top: 6px;
	padding: 10px 10px 10px 12px;
	background: $backgroundPrimary;
	border-radius: 15px;

	@include mobile-medium-min {
		padding: 16px 10px 16px 12px;
	}

	&__overico,
	&__close-btn {
		width: 24px;
		height: 24px;

		svg {
			width: 24px;
			height: 24px;
		}
	}

	&__overico {
		margin-right: 8px;
	}

	&__text {
		width: calc(100% - 64px);
		font-size: 16px;
		line-height: 20px;
		color: #a3a3a3;
		white-space: break-spaces;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__close-btn {
		margin-left: 8px;

		svg {
			pointer-events: none;
		}
	}

	&--have-img {
		#{$block}__overico {
			border-radius: 50%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
		}
	}
}
