@import 'assets/styles/imports';

.main-bottom {
	position: relative;
	flex-shrink: 0;
	width: 100%;
	min-height: 50px;
	background: $black;
	border-top: 1px solid $borderPrimaryColorOpacity20;
	z-index: 102;

	&--pt {
		padding-top: 44px;
	}
}
