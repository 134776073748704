@import 'assets/styles/_imports';

.users-modal {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;

	.modal__close {
		top: -1px;
		right: 0px;

		@include mobile-medium-min {
			width: 20px;
			height: 20px;
			top: 4px;
			right: 0;
		}
	}

	&__title {
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		font-weight: 500;
		color: $textPrimary;
		text-align: center;
		margin-bottom: 24px;

		@include mobile-medium-min {
			font-size: 20px;
			line-height: 28px;
			letter-spacing: normal;
			margin-bottom: 33px;
		}
	}

	&__user-container {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__user-avatar {
		width: 36px;
		height: 36px;
		margin-right: 12px;
		border-radius: 50%;
		flex-shrink: 0;
		overflow: hidden;

		@include mobile-medium-min {
			width: 40px;
			height: 40px;
			margin-right: 20px;
		}
	}

	&__user-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__filter {
		display: flex;
		width: 100%;
		margin-bottom: 10px;

		@include mobile-medium-min {
			margin-bottom: 13px;
		}
	}

	&__filter-btn {
		display: flex;
		align-items: center;
		padding: 0 9px 5px;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: 0.25px;
		text-transform: uppercase;
		color: $textPrimaryOpacity40;
		border-bottom: 1px solid transparent;
		transition: color $duration, border $duration;
		user-select: none;
		cursor: pointer;

		@include mobile-medium-min {
			font-size: 16px;
			line-height: 20px;
			letter-spacing: normal;
			padding: 0 10px 5px 12px;
		}

		&.active {
			color: $primary;
			border-bottom-color: $primary;

			svg {
				path {
					fill: $primary;
				}
			}
		}

		svg {
			position: relative;
			top: -1px;
			width: 14px;
			height: 13px;
			margin-right: 8px;
			pointer-events: none;

			@include mobile-medium-min {
				width: 18px;
				height: 18px;
				margin-right: 10px;
			}

			path {
				fill: $textPrimaryOpacity40;
				transition: fill $duration;
			}
		}
	}

	&__body {
		flex-grow: 1;
		max-height: calc(100% - 62px);
	}

	&__axis-y {
		width: 100%;
		height: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__users-list {
		height: 100%;
	}

	&__user {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__user-wrapper {
		position: relative;
		border-bottom: 1px solid $borderPrimaryColorOpacity20;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 48px);

		@include mobile-medium-min {
			width: calc(100% - 60px);
		}
	}

	&__user-name {
		display: flex;
		align-items: center;
		width: calc(100% - 24px);
	}

	&__user-name-item {
		display: block;
		min-height: 50px;
		font-size: 15px;
		line-height: 50px;
		letter-spacing: -0.23px;
		font-weight: 500;
		color: $textPrimary;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		@include mobile-medium-min {
			min-height: 64px;
			font-weight: 500;
			font-size: 16px;
			line-height: 64px;
			letter-spacing: normal;
		}
	}

	&__user-admin {
		display: flex;
		align-items: center;
		position: relative;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		font-weight: 500;
		color: $textPrimaryOpacity60;
		margin-left: 3px;

		&::before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			position: relative;
			margin-right: 3px;
			margin-bottom: 1px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='5' fill='white'/%3E%3Cpath d='M4.29912 13.3599H5.60927C5.72559 13.3599 5.81131 13.3905 5.89702 13.4762L6.82759 14.4008C7.62348 15.2028 8.37039 15.1967 9.16628 14.4008L10.0969 13.4762C10.1887 13.3905 10.2683 13.3599 10.3907 13.3599H11.7009C12.8213 13.3599 13.3539 12.8273 13.3539 11.7007V10.3905C13.3539 10.2742 13.3906 10.1884 13.4763 10.1027L14.4008 9.17209C15.2028 8.37616 15.1967 7.62921 14.4008 6.83327L13.4763 5.90265C13.3845 5.81081 13.3539 5.73121 13.3539 5.61489V4.30466C13.3539 3.17811 12.8274 2.64545 11.7009 2.64545H10.3907C10.2683 2.64545 10.1826 2.61483 10.0969 2.52912L9.16628 1.59849C8.37039 0.796434 7.62348 0.802556 6.82759 1.60461L5.89702 2.52912C5.81131 2.60871 5.72559 2.64545 5.60927 2.64545H4.29912C3.1665 2.64545 2.63999 3.16586 2.63999 4.30466V5.61489C2.63999 5.73121 2.60938 5.81693 2.52367 5.90265L1.59921 6.83327C0.797204 7.62921 0.803326 8.37616 1.59921 9.17209L2.52367 10.1027C2.60938 10.1884 2.63999 10.2742 2.63999 10.3905V11.7007C2.63999 12.8273 3.17263 13.3599 4.29912 13.3599ZM7.31737 11.0211C7.08473 11.0211 6.90106 10.9231 6.72352 10.7027L5.22357 8.87821C5.11337 8.73739 5.05827 8.59657 5.05827 8.43738C5.05827 8.12513 5.30928 7.87411 5.62152 7.87411C5.81131 7.87411 5.95824 7.94146 6.11742 8.1435L7.29901 9.63741L9.82748 5.59039C9.96217 5.37611 10.1336 5.2659 10.3234 5.2659C10.6295 5.2659 10.9111 5.48019 10.9111 5.80468C10.9111 5.9455 10.8377 6.09857 10.7519 6.23326L7.88062 10.6966C7.74593 10.9109 7.55002 11.0211 7.31737 11.0211Z' fill='%230D6EFF'/%3E%3C/svg%3E");
			background-size: cover;
			top: 0;
		}
	}

	&__user-icon {
		font-size: 0;
		line-height: 0;
		width: 16px;
		height: 9px;
		opacity: 0.4;
		transform: rotate(-90deg);
		background-position: center;
		background-size: 16px 9px;
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8L15 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

		&--active {
			opacity: 1;
			transform: rotate(0);
		}
	}

	&__user-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
	}

	&__user-controls {
		padding-left: 48px;
		padding-right: 16px;
		width: 100%;
		display: none;

		@include mobile-medium-min {
			padding-left: 60px;
			padding-right: 12px;
		}

		&--active {
			display: block;
		}
	}

	&__user-controls-item {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 8px 0;
		border-bottom: 1px solid $borderPrimaryColorOpacity20;

		@include mobile-medium-min {
			padding: 9px 0;
		}
	}

	&__user-controls-action {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 0;
		line-height: 0;
	}

	&__user-controls-icon {
		width: 36px;
		height: 36px;

		&--red {
			path {
				fill: $danger !important;
			}
		}
	}

	&__user-controls-title {
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		font-weight: 500;
		color: $textPrimary;

		@include mobile-medium-min {
			font-size: 15px;
			line-height: 20px;
			letter-spacing: -0.23px;
			font-weight: 400;
			margin-bottom: 2px;
		}
	}

	&__user-controls-subtitle {
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity40;

		@include mobile-medium-min {
			letter-spacing: -0.078px;
		}
	}
}
