@import 'assets/styles/imports';

.pinned-message {
	position: fixed;
	top: 44px;
	left: 0;
	right: 0;
	min-height: 48px;
	display: flex;
	align-items: center;
	padding: 8px 16px;
	background: $black;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	z-index: 4;

	&__container {
		max-width: 80%;
		display: flex;
		align-items: center;
		position: relative;
		font-size: 0;
		line-height: 0;
	}

	&__scrollbtn {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__icon {
		margin-right: 12px;
		flex-shrink: 0;
	}

	&__title {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: $textPrimary;
		margin-bottom: 4px;
	}

	&__text {
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		color: $textPrimaryOpacity60;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__body {
		max-width: 100%;
	}

	&__unpin {
		width: 24px;
		height: 24px;
		font-size: 0;
		line-height: 0;
		margin-left: auto;
		flex-shrink: 0;
		background-size: 16px 16px;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8M15 15L8 8M8 8L15 1L1 15' stroke='%23999999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	}
}
