@import 'assets/styles/_imports';

.avatar-form {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	height: calc(100% - 54px);

	&__picked {
		width: calc(100% / 6 - 10px);
		border-radius: 50%;
		margin-bottom: 12px;
		overflow: hidden;
	}

	&__picked-img {
		width: 100%;
		padding-top: 100%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&__label {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimaryOpacity60;
		margin-bottom: 16px;

		@include mobile-medium-min {
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.281px;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 7px);
		@include mobile-medium-min {
			width: calc(100% + 10px);
		}
	}

	&__list-spacer {
		margin-top: 100%;
	}

	&__list-item {
		display: inline-block;
		position: relative;
		margin-right: 10px;
		margin-bottom: 10px;
		width: calc(100% / 6 - 10px);
	}

	&__item {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		user-select: none;
		cursor: pointer;

		&:before,
		&:after {
			@include ps-elem;
			position: absolute;
			border-radius: 50%;
			opacity: 0;
			visibility: hidden;
			transition: opacity $duration, visibility $duration;
			pointer-events: none;
		}

		&:hover,
		&:active {
			.avatar-form__item-img {
				&::before {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	&__item-input {
		display: none;
		overflow: hidden;
		opacity: 0;
	}

	&__item-input:checked ~ &__item-checkmark {
		opacity: 1;
		visibility: visible;
	}

	&__item-img {
		display: block;
		width: 100%;
		height: 100%;
		background-size: cover;
		border-radius: 50%;
	}

	&__item-checkmark {
		display: block;
		position: absolute;
		bottom: -5px;
		right: -5px;
		width: 40%;
		height: 40%;
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.3636' cy='12.3636' r='9.06669' fill='%23DBA742'/%3E%3Cpath d='M11.401 16.4849C11.6476 16.4849 11.8383 16.3805 11.9732 16.1764L16.3456 9.22944C16.4433 9.07285 16.4851 8.93998 16.4851 8.81186C16.4851 8.47495 16.2525 8.24243 15.9176 8.24243C15.6897 8.24243 15.5502 8.32785 15.4106 8.55087L11.3778 15.0755L9.30787 12.366C9.16832 12.181 9.02413 12.105 8.81946 12.105C8.48455 12.105 8.24268 12.347 8.24268 12.6792C8.24268 12.8263 8.29384 12.9687 8.41478 13.1158L10.8243 16.1812C10.9871 16.39 11.1592 16.4849 11.401 16.4849Z' fill='black'/%3E%3C/svg%3E%0A")
			no-repeat center;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.4s, visibility 0.4s;
		z-index: 1;

		@include mobile-medium-min {
			bottom: -4px;
			right: -2px;
			width: 40%;
			height: 40%;
		}
	}

	&__btn {
		margin-top: auto;
	}

	&__axis-y {
		flex-grow: 1;
		width: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: 30px;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}
}
