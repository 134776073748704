@import 'assets/styles/_imports';

.alert {
	width: 300px;
	border-radius: 8px;
	background-color: #171717;
	box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);

	@include mobile-medium-min {
		width: calc(100% - 40px);
		max-width: 500px;
	}

	&__header {
		padding: 19px 16px;

		@include mobile-medium-min {
			padding: 20px 20px;
		}
	}

	&__title {
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		font-weight: 500;
		color: $textPrimary;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;

		@include mobile-medium-min {
			font-size: 20px;
			line-height: 28px;
			font-weight: 500;
			text-align-last: left;
			text-align: left;
		}
	}

	&__subtitle {
		font-size: 13px;
		line-height: 16px;
		letter-spacing: -0.08px;
		color: $textPrimaryOpacity80;
		text-align: center;
		margin-top: 7px;

		@include mobile-medium-min {
			text-align-last: left;
		}
	}

	&__btns {
		display: flex;
		flex-direction: column;
		padding: 11px 16px;

		@include mobile-medium-min {
			flex-direction: row;
			justify-content: flex-end;
			padding: 14px 24px;
		}
	}

	&__btn {
		margin-bottom: 22px;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: -0.31px;
		color: $primary;
		font-weight: 500;

		@include mobile-medium-min {
			margin-bottom: 0;
			margin-right: 24px;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.01em;
			text-transform: uppercase;
		}

		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}

		&:last-child {
			margin-bottom: 0;

			@include mobile-medium-min {
				margin-bottom: 0;
				margin-right: 0;
			}
		}

		&--destructive {
			color: $danger;
		}
	}
}
