@import 'assets/styles/_imports';

.blocked-users {
	$block: '.blocked-users';

	&--empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
		padding-left: 16px;
		padding-right: 16px;
		flex-grow: 1;

		@include mobile-medium-min {
			padding: 0;
		}
	}

	&__text {
		text-align: center;
		font-size: 16px;
		line-height: 20px;
		color: $textPrimary;

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;

		&--accent {
			#{$block}__item-btn {
				color: $danger;
				background: rgba(206, 48, 48, 0.2);
			}
		}
	}

	&__item-avatar {
		flex-shrink: 0;
		width: 36px;
		height: 36px;
		margin-right: 12px;
		border-radius: 18px;
		overflow: hidden;

		@include mobile-medium-min {
			width: 40px;
			height: 40px;
			margin-right: 20px;
			border-radius: 20px;
		}
	}

	&__item-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__item-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 48px);
		border-bottom: 1px solid rgba(84, 84, 88, 0.65);
		flex-grow: 1;

		@include mobile-medium-min {
			width: calc(100% - 60px);
		}
	}

	&__item-name {
		display: block;
		max-width: calc(100% - 48px);
		padding: 16px 0 18px;
		color: $textPrimary;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@include mobile-medium-min {
			max-width: calc(100% - 52px);
			padding: 19px 0 21px;
			font-size: 16px;
			line-height: 23px;
		}
	}

	&__item-btn {
		display: block;
		flex-shrink: 0;
		margin-left: 12px;
		padding: 4px 8px;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: $textThird;
		background: $backgroundPrimary;
		border-radius: 4px;
		border: none;
		cursor: pointer;
		user-select: none;
		transition: color $duration, background $duration;
	}
}
