@import 'assets/styles/_imports';

.short-rules {
	padding-bottom: 16px;

	&__items {
		margin-bottom: 20px;
		counter-reset: rules;
	}

	&__item {
		position: relative;
		margin-bottom: 20px;
		padding-left: 52px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			counter-increment: rules;
			content: counters(rules, '.') ' ';
			width: 37px;
			height: 37px;
			font-weight: 500;
			font-size: 18px;
			line-height: 37px;
			text-align: center;
			color: $textPrimary;
			background-color: #404040;
			border-radius: 22px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__item-title,
	&__item-text {
		font-size: 16px;
		line-height: 20px;
	}

	&__item-title {
		color: $textPrimary;
	}

	&__item-text {
		color: $textThird;
	}

	&__btn {
		display: block;
		margin-left: 52px;
		padding: 4px 0;
		font-size: 16px;
		line-height: 20px;
		color: $primary;
		text-align: left;
	}
}
