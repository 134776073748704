@import 'assets/styles/_imports';

.waiting-speakers {
	&--empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	&__text {
		text-align: center;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.078px;
		color: rgba(255, 255, 255, 0.4);

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.43px;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		width: 100%;
	}

	&__item-avatar,
	&__item-btn {
		flex-shrink: 0;
		width: 36px;
		height: 36px;
		border-radius: 18px;

		@include mobile-medium-min {
			width: 40px;
			height: 40px;
			border-radius: 20px;
		}
	}

	&__item-avatar {
		margin-right: 12px;
		overflow: hidden;

		@include mobile-medium-min {
			margin-right: 20px;
		}
	}

	&__item-avatar-img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__item-right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: calc(100% - 48px);
		padding: 16px 0;
		border-bottom: 1px solid rgba(84, 84, 88, 0.65);
		flex-grow: 1;

		@include mobile-medium-min {
			padding: 11px 0 12px;
			width: calc(100% - 60px);
		}
	}

	&__item-name {
		display: block;
		max-width: calc(100% - 106px);
		margin-right: 16px;
		color: #ffffff;
		font-size: 17px;
		line-height: 22px;
		letter-spacing: -0.43px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;

		@include mobile-medium-min {
			font-size: 16px;
			line-height: 23px;
			font-weight: 500;
		}
	}

	&__item-btns {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__item-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		background: $backgroundInverseOpacity20;

		&--close {
			background: rgba($danger, 0.2);
		}

		& + & {
			margin-left: 16px;
		}

		svg {
			pointer-events: none;
		}
	}
}
