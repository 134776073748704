@import 'assets/styles/_imports';

.poll-edit {
	max-width: 452px;
	margin-left: auto;
	margin-right: auto;

	&__head {
		padding: 16px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.1);
	}

	&__title {
		font-weight: 500;
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimary;
		word-wrap: break-word;
	}
}
