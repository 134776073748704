@import 'assets/styles/_imports';

.onboard {
	display: flex;
	flex-direction: column;
	background-color: $backgroundPrimary;
	padding: 16px 20px 32px;
	height: 100%;
	width: 100%;

	@include mobile-medium-min {
		// padding: 71px 30px 30px;
		padding: 30px;
	}

	&__title {
		color: $textPrimary;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		text-align: center;
		margin-bottom: 20px;

		@include mobile-medium-min {
			font-weight: 500;
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.38px;
			margin-bottom: 29px;
		}
	}

	&__text {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimaryOpacity40;

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.43px;
		}
	}

	&__restore-wrapper {
		margin-top: auto;
		text-align: center;
	}

	&__watchers {
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textPrimaryOpacity40;
		margin-bottom: 20px;
	}

	&__loader {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $backgroundPrimary;
		z-index: 100000;

		svg {
			width: 36px;
			height: 36px;
			margin: auto;
		}
	}
}
