$primary: #dba742;

$textPrimary: rgba(255, 255, 255, 1);
$textPrimaryOpacity80: rgba(255, 255, 255, 0.8);
$textPrimaryOpacity60: rgba(255, 255, 255, 0.6);
$textPrimaryOpacity40: rgba(255, 255, 255, 0.4);
$textPrimaryOpacity20: rgba(255, 255, 255, 0.2);
$textPrimaryOpacity10: rgba(255, 255, 255, 0.1);

$textSecond: #171717;
$textSecondOpacity80: rgba(0, 0, 0, 0.8);
$textSecondOpacity60: rgba(0, 0, 0, 0.6);
$textSecondOpacity40: rgba(0, 0, 0, 0.4);

$textThird: #a3a3a3;

$backgroundPrimary: #262626;
$backgroundInverse: #ffffff;
$backgroundInverseOpacity80: rgba(255, 255, 255, 0.8);
$backgroundInverseOpacity60: rgba(255, 255, 255, 0.6);
$backgroundInverseOpacity40: rgba(255, 255, 255, 0.4);
$backgroundInverseOpacity20: rgba(255, 255, 255, 0.2);
$backgroundInverseOpacity10: rgba(255, 255, 255, 0.1);
$backgroundInverseOpacityZero: rgba(255, 255, 255, 0);

$backgroundSecond: #1d1f23;

$borderPrimaryColor: rgba(255, 255, 255, 1);
$borderPrimaryColorOpacity60: rgba(255, 255, 255, 0.6);
$borderPrimaryColorOpacity40: rgba(255, 255, 255, 0.4);
$borderPrimaryColorOpacity20: rgba(255, 255, 255, 0.2);
$borderPrimaryColorOpacity10: rgba(255, 255, 255, 0.1);

$danger: #ce3030;
$success: #4e8634;
$purple: rgba(123, 56, 165, 1);
$black: #000000;
$btndisabled: #525252;

$blue: #007dc5;
