@import 'assets/styles/_imports';

.slow-mode-time {
	&__items {
		padding: 0 15px;
	}

	&__item {
		border-bottom: 1px solid $borderPrimaryColorOpacity10;
	}

	&__button {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 11px 0;
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		color: $textPrimaryOpacity80;

		&--active {
			&:after {
				@include ps-elem;
				flex-shrink: 0;
				width: 18px;
				height: 18px;
				margin-left: 12px;
				background: url("data:image/svg+xml,%3Csvg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.89729 18C7.43567 18 7.85214 17.772 8.14673 17.3264L17.6953 2.15544C17.9086 1.81347 18 1.52332 18 1.24352C18 0.507772 17.4921 0 16.7607 0C16.263 0 15.9582 0.186529 15.6535 0.673575L6.8465 14.9223L2.32619 9.00518C2.02144 8.60104 1.70655 8.43523 1.25959 8.43523C0.528217 8.43523 0 8.96373 0 9.68912C0 10.0104 0.111738 10.3212 0.375847 10.6425L5.6377 17.3368C5.99323 17.7927 6.36907 18 6.89729 18Z' fill='%23dba742'/%3E%3C/svg%3E%0A")
					no-repeat center;
			}
		}
	}
}
