@import '@sizes';

@mixin large {
	@media screen and (min-width: #{$width-large-breakpoint}px) {
		@content;
	}
}

@mixin desktop {
	@media screen and (max-width: #{$width-large-breakpoint}px) {
		@content;
	}
}

@mixin desktop-medium {
	@media screen and (max-width: #{$width-desktop-medium-breakpoint}px) {
		@content;
	}
}

@mixin desktop-min {
	@media screen and (max-width: #{$width-desktop-min-breakpoint}px) {
		@content;
	}
}

@mixin laptop {
	@media screen and (max-width: #{$width-desktop-breakpoint - 1}px) {
		@content;
	}
}

// < 1180
@mixin tablet {
	@media screen and (max-width: #{$width-laptop-breakpoint - 1}px) {
		@content;
	}
}

// < 1024
@mixin tablet-small {
	@media screen and (max-width: #{$width-tablet-large-breakpoint - 1}px) {
		@content;
	}
}

@mixin tablet-small-min {
	@media screen and (min-width: #{$width-tablet-large-breakpoint}px) {
		@content;
	}
}

@mixin tablet-land {
	@media screen and (max-width: #{$width-laptop-breakpoint - 1}px) and (orientation: landscape) {
		@content;
	}
}

@mixin tablet-port {
	@media screen and (max-width: #{$width-laptop-breakpoint - 1}px) and (orientation: portrait) {
		@content;
	}
}

// < 768
@mixin mobile {
	@media screen and (max-width: #{$width-tablet-breakpoint - 1}px) {
		@content;
	}
}

// < 640
@mixin mobile-big {
	@media screen and (max-width: #{$width-mobile-big - 1}px) {
		@content;
	}
}

// < 480
@mixin mobile-medium {
	@media screen and (max-width: #{$width-mobile-medium - 1}px) {
		@content;
	}
}

// > 480
@mixin mobile-medium-min {
	@media screen and (min-width: #{$width-mobile-medium}px) {
		@content;
	}
}

// < 375
@mixin mobile-small {
	@media screen and (max-width: #{$width-mobile-small - 1}px) {
		@content;
	}
}

// sorry
@mixin mobile320 {
	@media screen and (max-width: 359px) {
		@content;
	}
}

@mixin mobile360 {
	@media screen and (min-width: 360px) and (max-width: 413px) {
		@content;
	}
}

@mixin mobile414 {
	@media screen and (min-width: 414px) {
		@content;
	}
}