@import '../imports';

html,
body,
#root {
	width: 100%;
	height: 100%;
}

#root {
	position: relative;
	overflow: hidden;
}

html {
	font: 400 16px/21px $font-SFProText;
}

body {
	color: #fff;
	background: var(--bg-color-primary);
}

.link {
	color: $primary;
}

.main-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: hidden;

	&--show-record {
		.chat {
			margin-bottom: 40px;
		}
	}
}

@for $i from 1 through 5 {
	.color-#{$i} {
		color: var(--nick-name-color#{$i});
	}
}
