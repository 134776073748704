@import 'assets/styles/imports';

.link-preview-sceleton {
	width: 320px;
	margin-top: 8px;
	border-radius: 6px;

	@include mobile360 {
		width: 294px;
	}

	@include mobile320 {
		width: 238px;
	}

	&__container {
		margin-bottom: 2px;
	}
}

.react-loading-skeleton {
	--base-color: #8c8c8c;
	--highlight-color: #666;
}

.chat__message--right {
	.link-preview-sceleton {
		width: 254px;

		@include mobile320 {
			width: 238px;
		}
	}

	.react-loading-skeleton {
		--base-color: #8c8c8c;
		--highlight-color: #666;
	}
}
