@import 'assets/styles/_imports';

.poll-votes-anim {
	position: absolute;
	top: 98px;
	left: 0;
	width: 100%;
	height: 72px;
	padding-right: 16px;
	pointer-events: none;
	z-index: 101;

	@include mobile-medium-min {
		top: 110px;
	}

	&--down {
		top: 142px;

		@include mobile-medium-min {
			top: 158px;
		}
	}

	&__item {
		width: 28px;
		height: 28px;
		position: absolute;
		top: 0;
		right: 16px;
		opacity: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		transform-origin: top right;
		-webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cpath style='fill:%23C4C4C4;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3C/svg%3E");
		mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cpath style='fill:%23C4C4C4;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3Cpath style='fill:none;' d='M0,16.8C0,3,3,0,16.8,0h2.4C33,0,36,3,36,16.8v2.4C36,33,33,36,19.2,36h-2.4C3,36,0,33,0,19.2V16.8z' /%3E%3C/svg%3E");
		animation-duration: 1.2s;
		animation-timing-function: linear;
		animation-fill-mode: forwards;

		@for $i from 1 through 5 {
			&[data-anim='anim#{$i}'] {
				animation-name: anim#{$i};
			}
		}

		@for $i from 6 through 10 {
			&[data-anim='anim#{$i}'] {
				animation-name: anim#{$i - 5};
			}
		}

		@for $i from 1 through 20 {
			&:nth-child(#{$i}) {
				animation-delay: #{0.2 + ($i * 0.05)} + 's';
			}
		}
	}
}

@import './animStyles/anim1.scss';
@import './animStyles/anim2.scss';
@import './animStyles/anim3.scss';
@import './animStyles/anim4.scss';
@import './animStyles/anim5.scss';
