@import 'assets/styles/_imports';

.restore {
	display: flex;
	flex-direction: column;
	background-color: #171717;
	padding: 16px 20px 32px;
	height: 100%;

	@include mobile-medium-min {
		// padding: 71px 30px 30px;
		padding: 30px;
	}

	&__title {
		color: $textPrimary;
		font-weight: 500;
		font-size: 16px;
		line-height: 21px;
		letter-spacing: -0.31px;
		text-align: center;
		margin-bottom: 20px;

		@include mobile-medium-min {
			font-weight: 500;
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 0.38px;
			margin-bottom: 29px;
		}
	}

	&__text {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimaryOpacity40;

		@include mobile-medium-min {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.43px;
		}
	}

	&__wrapper {
		margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
	}

	&__watchers {
		font-weight: normal;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $textPrimaryOpacity40;
		margin-bottom: 20px;
	}

	&__footer-btn {
		width: 100%;
	}
}
