@import 'assets/styles/_imports';

.poll-option-voting {
	$block: '.poll-option-voting';

	width: 100%;
	display: flex;
	align-items: baseline;

	&__number {
		width: 46px;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		letter-spacing: -0.08px;
		color: $backgroundInverseOpacity80;
	}

	&__indicate {
		width: 100px;
		height: 5px;

		span {
			display: block;
			max-width: 100%;
			height: 100%;
			background-color: $backgroundInverseOpacity80;
			border-radius: 5px;
		}
	}
}