@font-face {
	font-family: 'Halvar Mittel';
	src: url('HalvarMittel-Bd.woff2') format('woff2'),
		url('HalvarMittel-Bd.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Halvar Mittel';
	src: url('HalvarMittel-Md.woff2') format('woff2'),
		url('HalvarMittel-Md.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Halvar Mittel';
	src: url('HalvarMittel-Lt.woff2') format('woff2'),
		url('HalvarMittel-Lt.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Halvar Mittel';
	src: url('HalvarMittel-Rg.woff2') format('woff2'),
		url('HalvarMittel-Rg.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
