@import 'assets/styles/imports';

.chat {
	$block: '.chat';

	width: 100%;
	height: 100%;
	background-color: $black;
	overflow: hidden;
	position: relative;
	transition: background $duration;

	&--overflow {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(#000, 0.4);
			z-index: 3;
		}
	}

	&__axis-y {
		display: flex;
		width: 100%;
		height: 100%;
		padding: 0 3px;
		overflow: hidden scroll;
		opacity: 0;
		visibility: hidden;
		transition: $duration opacity, $duration visibility;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&.visible {
			opacity: 1;
			visibility: visible;
		}
	}

	&__messages {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 100%;
		padding-top: 60px;

		&--wpinned {
			#{$block}__message:first-child {
				padding-top: 50px;
			}
		}
	}

	&__message {
		width: 100%;
		margin-top: 6px;

		&:first-child {
			margin-top: auto;

			#{$block}__message-date {
				padding-top: 14px;
			}
		}

		&:last-child {
			padding-bottom: 8px;
		}

		&--no-avatar {
			& + &,
			& + #{$block}__message {
				margin-top: 3px;

				#{$block}__message-date {
					padding-top: 21px;
				}
			}
		}

		&--is-hidden {
			#{$block}__message-right {
				max-width: calc(100% - 108px);
			}

			#{$block}__message-body {
				opacity: 0.5;
				background: rgb(29, 31, 35, 0.6);

				&:after {
					@include ps-elem;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: -26px;
					width: 18px;
					height: 18px;
					background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23FF2D55' fill-opacity='0.2' /%3E%3Crect x='5' y='10' width='14' height='4' rx='2' fill='%23FF2D55' /%3E%3C/svg%3E")
						no-repeat center;
					background-size: contain;
					pointer-events: none;
					z-index: 1;
				}
			}
		}

		&--right {
			align-self: flex-end;

			#{$block}__message-container {
				justify-content: flex-end;
			}

			#{$block}__message-left {
				order: 2;
				margin-right: 0;
				margin-left: 8px;
			}

			#{$block}__message-right {
				order: 1;
				align-items: flex-end;
				max-width: calc(100% - 16px);

				@include mobile320 {
					max-width: 261px;
				}

				@include mobile360 {
					max-width: 310px;
				}

				@include mobile414 {
					max-width: 310px;
				}
			}

			#{$block}__message-body {
				background-color: #0d6eff;
			}

			#{$block}__message-text-item {
				color: $textPrimary;
			}

			#{$block}__message-time {
				color: $textThird;
			}

			#{$block}__message-text a {
				color: $textSecond;
				border-bottom-color: $textSecond;
			}

			#{$block}__message-sticker {
				margin-left: auto;
			}

			.link-preview__sitename,
			.link-preview__description {
				color: $textSecond;
			}

			.link-preview:before {
				background-color: $textSecond;
			}

			.reactions {
				margin-left: auto;

				&__items {
					justify-content: flex-end;
				}
			}

			.poll-option-voting {
				&__number {
					color: $textSecondOpacity60;
				}

				&__indicate {
					span {
						background-color: $textSecondOpacity60;
					}
				}
			}

			&#{$block}__message--mention {
				#{$block}__message-right {
					@include mobile414 {
						max-width: 310px;
					}
				}
			}

			&#{$block}__message--is-hidden {
				#{$block}__message-body:after {
					right: auto;
					left: -26px;
				}

				#{$block}__message-right {
					max-width: calc(100% - 92px);
				}
			}

			&#{$block}__message--poll-results {
				#{$block}__message-body {
					background-color: $primary;
				}
			}

			&#{$block}__message--sticker {
				&#{$block}__message--reactions {
					.reactions__item-btn {
						color: $textPrimaryOpacity60;
						background: $backgroundInverseOpacity10;

						&--selected {
							background: #428bf9;
						}
					}
				}
			}

			&#{$block}__message--reactions {
				#{$block}__message-text {
					display: block;
				}

				.reactions {
					&__item-btn {
						color: $textPrimaryOpacity60;
						background: rgba(0, 0, 0, 0.1);

						&--selected {
							color: #262626;
							background: #fff;
						}
					}
				}
			}

			.bet__events-btn {
				background-color: rgb(13 110 255 / 91%);
			}
		}

		&--mention {
			&#{$block}__message--right {
				#{$block}__message-replay {
					&:before {
						background-color: $textPrimary;
					}

					#{$block}__message-author,
					#{$block}__message-text {
						color: $textPrimary;
					}
				}

				@include mobile320 {
					#{$block}__message-replay #{$block}__message-text {
						font-size: 14px;
						letter-spacing: -0.204803px;
					}
				}

				@include mobile414 {
					#{$block}__message-replay #{$block}__message-text {
						font-size: 15px;
						letter-spacing: -0.23px;
					}
				}
			}

			@include mobile320 {
				#{$block}__message-author {
					letter-spacing: -0.628764px;
					line-height: 16px;
				}

				#{$block}__message-right {
					max-width: 290px;
				}

				#{$block}__message-replay {
					padding-right: 5px;
					padding-left: 7px;
				}

				#{$block}__message-replay #{$block}__message-text {
					letter-spacing: -0.1px;
					margin-top: 2px;
					line-height: 15px;
				}
			}

			@include mobile360 {
				#{$block}__message-replay {
					padding-right: 5px;
				}

				#{$block}__message-replay #{$block}__message-text {
					letter-spacing: -0.078px;
				}
			}

			@include mobile414 {
				#{$block}__message-replay {
					padding-right: 5px;
				}

				#{$block}__message-replay #{$block}__message-text {
					letter-spacing: -0.078px;
				}
			}
		}

		&--highlight {
			padding: 14px 0;

			#{$block}__message-body {
				padding: 0;
				background-color: transparent;
			}

			> #{$block}__message-date,
			& + #{$block}__message #{$block}__message-date {
				padding-top: 0;
			}
		}

		&--sticker {
			#{$block}__message-body {
				padding: 0;
				background: none;
			}

			#{$block}__message-text {
				display: flex;
				align-items: flex-end;
			}
		}

		&--reactions {
			#{$block}__message-text {
				display: block;
			}

			#{$block}__message-time {
				margin-top: 4px;
			}
		}

		&--submenu {
			#{$block}__message-container {
				z-index: 3;
			}

			.bet {
				&__events-btn {
					background-color: rgba(61, 61, 61, 0.9);
				}
			}
		}

		.bet {
			margin-bottom: 8px;
		}
	}

	&__message-date {
		display: flex;
		justify-content: center;
		padding-top: 18px;
		padding-bottom: 24px;
	}

	&__message-date-item {
		height: 22px;
		padding-left: 8px;
		padding-right: 8px;
		text-align: center;
		font-size: 11px;
		line-height: 22px;
		border-radius: 11px;
		color: rgba(255, 255, 255, 0.7);
		background-color: #2c2c2e;
	}

	&__message-container {
		display: flex;
		align-items: flex-end;
		width: 100%;
		user-select: none;
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background: #797979;
			opacity: 0;
			visibility: hidden;
			pointer-events: none;
			transition: 0.4s opacity, 0.4s visibility;
		}

		&--lighted {
			&:after {
				opacity: 0.3;
				visibility: visible;
			}
		}
	}

	&__message-not-viewed {
		display: block;
		width: 100%;
		line-height: 30px;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.6);
		background-color: #e4e4e4;
		margin-top: 10px;
		text-align: center;
	}

	&__message-header {
		display: flex;
		align-items: center;
		margin-bottom: 2px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__message-left {
		width: 35px;
		flex-shrink: 0;
		margin-top: -5px;
		margin-right: 6px;

		@include mobile320 {
			width: 30px;
		}
	}

	&__message-avatar {
		position: relative;
		width: 100%;
		padding-top: 100%;
		border-radius: 50%;
	}

	&__message-avatar-img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1;
		border-radius: 50%;
		pointer-events: none;
	}

	&__message-right {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		max-width: calc(100% - 57px);

		@include mobile320 {
			max-width: calc(100% - 52px);
		}

		@include mobile360 {
			max-width: calc(100% - 52px);
		}

		@include mobile414 {
			max-width: calc(100% - 52px);
		}
	}

	&__message-author {
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: var(--nick-name-color);
		overflow: hidden;
		text-overflow: ellipsis;

		& + #{$block}__message-author-status {
			margin-left: 4px;
		}

		@include mobile-medium-min {
			font-size: 12px;
			line-height: 16px;
			font-weight: bold;
		}

		@include mobile320 {
			letter-spacing: -0.6px;
		}
	}

	&__message-author-status {
		height: 18px;
		flex-shrink: 0;
		padding-left: 6px;
		padding-right: 6px;
		font-size: 13px;
		line-height: 18px;
		color: $textSecond;
		border-radius: 9px;

		&--top {
			background-color: #f0f0f0;
		}

		&:not(:last-child) {
			margin-right: 4px;
		}
	}

	&__message-author-admin {
		position: relative;
		font-weight: 400;
		font-size: 13px;
		line-height: 18px;
		color: #949494;
		margin-left: 4px;
		display: flex;
		align-items: center;

		@include mobile320 {
			margin-left: 3px;
			letter-spacing: -0.7px;
		}

		@include mobile360 {
			margin-left: 1px;
		}

		@include mobile414 {
			margin-left: 1px;
		}

		&::before {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			position: relative;
			margin-right: 5px;
			margin-bottom: 1px;
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='5' fill='white'/%3E%3Cpath d='M4.29912 13.3599H5.60927C5.72559 13.3599 5.81131 13.3905 5.89702 13.4762L6.82759 14.4008C7.62348 15.2028 8.37039 15.1967 9.16628 14.4008L10.0969 13.4762C10.1887 13.3905 10.2683 13.3599 10.3907 13.3599H11.7009C12.8213 13.3599 13.3539 12.8273 13.3539 11.7007V10.3905C13.3539 10.2742 13.3906 10.1884 13.4763 10.1027L14.4008 9.17209C15.2028 8.37616 15.1967 7.62921 14.4008 6.83327L13.4763 5.90265C13.3845 5.81081 13.3539 5.73121 13.3539 5.61489V4.30466C13.3539 3.17811 12.8274 2.64545 11.7009 2.64545H10.3907C10.2683 2.64545 10.1826 2.61483 10.0969 2.52912L9.16628 1.59849C8.37039 0.796434 7.62348 0.802556 6.82759 1.60461L5.89702 2.52912C5.81131 2.60871 5.72559 2.64545 5.60927 2.64545H4.29912C3.1665 2.64545 2.63999 3.16586 2.63999 4.30466V5.61489C2.63999 5.73121 2.60938 5.81693 2.52367 5.90265L1.59921 6.83327C0.797204 7.62921 0.803326 8.37616 1.59921 9.17209L2.52367 10.1027C2.60938 10.1884 2.63999 10.2742 2.63999 10.3905V11.7007C2.63999 12.8273 3.17263 13.3599 4.29912 13.3599ZM7.31737 11.0211C7.08473 11.0211 6.90106 10.9231 6.72352 10.7027L5.22357 8.87821C5.11337 8.73739 5.05827 8.59657 5.05827 8.43738C5.05827 8.12513 5.30928 7.87411 5.62152 7.87411C5.81131 7.87411 5.95824 7.94146 6.11742 8.1435L7.29901 9.63741L9.82748 5.59039C9.96217 5.37611 10.1336 5.2659 10.3234 5.2659C10.6295 5.2659 10.9111 5.48019 10.9111 5.80468C10.9111 5.9455 10.8377 6.09857 10.7519 6.23326L7.88062 10.6966C7.74593 10.9109 7.55002 11.0211 7.31737 11.0211Z' fill='%230D6EFF'/%3E%3C/svg%3E");
			background-size: cover;

			@include mobile320 {
				margin-bottom: 0;
				top: -1px;
				width: 13px;
				height: 13px;
				margin-right: 2px;
			}

			@include mobile360 {
				margin-bottom: 0;
				top: -3px;
			}

			@include mobile414 {
				margin-bottom: 0;
				top: -3px;
			}
		}
	}

	&__message-body {
		position: relative;
		max-width: 100%;
		padding: 5px 8px;
		background-color: #1d1f23;
		border-radius: 8px;
		transition: background $duration;

		@include mobile-medium-min {
			padding: 12px 16px;
		}

		@include mobile320 {
			border-radius: 9px;
			padding: 6px 8px 7px;
		}

		@include mobile360 {
			border-radius: 12px;
			padding: 6px 8px;
		}

		@include mobile414 {
			border-radius: 12px;
			padding: 6px 8px;
		}
	}

	&__message-text {
		display: flex;
		align-items: flex-end;
		font-size: 15px;
		line-height: 20px;
		white-space: pre-wrap;
		word-break: break-word;
		color: $textPrimary;

		@include mobile-medium-min {
			font-size: 16px;
		}

		@include mobile320 {
			font-size: 14px;
			letter-spacing: -0.23px;
		}

		@include mobile360 {
			letter-spacing: -0.23px;
		}

		@include mobile414 {
			letter-spacing: -0.23px;
		}

		a {
			border-bottom: 1px solid $primary;
			transition: $duration border-color;
			color: $primary;

			@include tablet-small-min {
				&:hover {
					border-color: transparent;
				}
			}
		}

		&--multiline {
			display: block;

			#{$block}__message-time {
				@include mobile320 {
					bottom: 0;
				}

				@include mobile360 {
					bottom: 0;
				}

				@include mobile414 {
					bottom: 0;
				}
			}
		}
	}

	&__message-text-item {
		margin-right: auto;
	}

	&__message-sticker {
		width: 96px;
		height: 96px;
	}

	&__message-sticker-img {
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&__message-replay {
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;
		margin-bottom: 2px;
		padding-left: 8px;
		text-align: left;
		cursor: pointer;
		z-index: 2;

		@include mobile-medium-min {
			padding-left: 5px;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 3px;
			background-color: $backgroundInverse;
			border-radius: 3px;
			z-index: 1;
		}

		#{$block}__message-author {
			color: $textPrimary;
			margin-bottom: 0;

			@include mobile-medium-min {
				margin-bottom: 4px;
				font-size: 13px;
				line-height: 17px;
			}
		}

		#{$block}__message-text {
			display: block;
			padding: 0;
			font-size: 13px;
			line-height: 18px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			background: none;
			border-radius: 0;

			@include mobile320 {
				font-size: 12px;
			}
		}
	}

	&__message-replay-img {
		width: 36px;
		height: 36px;
		margin-right: 4px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		& + #{$block}__message-replay-content {
			width: calc(100% - 40px);
		}
	}

	&__message-replay-content {
		width: 100%;
	}

	&__message-time {
		position: relative;
		margin-left: 9px;
		margin-top: 2px;
		text-align: right;
		font-size: 10px;
		font-weight: 300;
		line-height: 12px;
		white-space: nowrap;
		color: $textPrimaryOpacity60;
	}

	&__textarea-wrap {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;

		&--warning {
			#{$block}__textarea-item {
				border-color: $danger;
				background-color: rgba($danger, 0.2);
			}

			#{$block}__text-length {
				color: $danger;
			}
		}
	}

	&__textarea-item {
		display: block;
		width: 100%;
		height: 36px;
		min-height: 36px;
		max-height: 78px;
		padding: 5.5px 48px 5.5px 12px;
		color: $textPrimary;
		font: 500 16px/22px $font-SFProText;
		letter-spacing: -0.4px;
		background: $black;
		border: 1px solid rgba(255, 255, 255, 0.2);
		transition: border $duration, background $duration;
		border-radius: 17px;
		overflow: hidden auto;
		-webkit-user-select: text;
		-ms-overflow-style: none;
		scrollbar-width: none;
		resize: none;

		&:focus {
			outline: none;
		}

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}

		&--wtext {
			padding-right: 75px !important;
		}

		@include placeholder {
			color: rgba(255, 255, 255, 0.2);
		}
	}

	&__submenu {
		position: absolute;
		width: 278px;
		top: 100%;
		left: 0;
		background-color: #171717;
		border-radius: 4px;
		opacity: 0;
		visibility: hidden;
		z-index: 103;

		@media screen and (max-width: 355px) {
			width: 276px;
		}

		@include mobile-medium-min {
			width: 304px;
		}

		&--reactions {
			top: calc(100% + 52px);
		}

		&--top {
			top: auto;
			bottom: calc(100% + 2px);
		}

		&--right {
			left: auto;
			right: 0;
		}

		&--visible {
			opacity: 1;
			visibility: visible;
			transition: opacity 0.2s 0.1s ease-in, visibility 0.2s 0.1s ease-in;
		}
	}

	&__submenu-items {
		display: flex;
		flex-wrap: wrap;

		&--have-small-2,
		&--have-small-3 {
			#{$block}__submenu-item--small {
				padding: 0;
				border-left: 1px solid #383a3d;
				border-bottom: 1px solid #383a3d;

				&:first-child {
					border-left: none;
				}

				#{$block}__submenu-btn {
					justify-content: center;
					height: 48px;
					font-size: 0;
					line-height: 0;
					color: transparent;
				}
			}
		}

		&--have-small-2 {
			#{$block}__submenu-item--small {
				width: 50%;
			}
		}

		&--have-small-3 {
			#{$block}__submenu-item--small {
				width: calc(100% / 3);
			}
		}
	}

	&__submenu-item {
		width: 100%;
		padding: 0 20px;
	}

	&__submenu-reactions {
		position: absolute;
		top: -50px;
		left: 0;
		max-width: 100%;
		height: 42px;
		padding: 3px;
		background-color: #343434;
		box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
		border-radius: 21px;
		z-index: 1;
	}

	&__submenu-reactions-axis-x {
		width: 100%;
		height: 100%;
		scrollbar-width: none;
		overflow: scroll hidden;

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
		}
	}

	&__submenu-reactions-items {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-shrink: 0;
		margin: 0 -2px;
	}

	&__submenu-reactions-btn {
		flex-shrink: 0;
		display: block;
		width: 36px;
		height: 36px;
		margin: 0 2px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 18px;
		cursor: pointer;
		user-select: none;
	}

	&__submenu-header {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid $borderPrimaryColorOpacity10;

		#{$block}__submenu-btn {
			height: 48px;
			font-size: 0;
			line-height: 0;
			padding: 0;
			margin: 0;
			border: none;
			align-items: center;
			justify-content: center;
			border-right: 1px solid $borderPrimaryColorOpacity10;

			&:last-child {
				border: none;
			}
		}
	}

	&__submenu-btn {
		width: 100%;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 13px 0;
		font-size: 15px;
		line-height: 21px;
		font-weight: normal;
		letter-spacing: -0.43px;
		color: $textPrimaryOpacity60;

		@include mobile-medium-min {
			font-size: 17px;
		}

		&:last-child {
			border: none;
		}

		&--warn {
			color: $danger;
		}

		&--success {
			color: $success;
		}

		* {
			pointer-events: none;
		}
	}

	&__submenu-icon,
	&__avatar-submenu-icon {
		width: 24px;
		height: 24px;
	}

	&__message-avatar-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&__avatar-submenu {
		position: absolute;
		width: 280px;
		bottom: 100%;
		left: 0;
		background-color: $backgroundPrimary;
		border-radius: 4px;
		padding: 0 20px;
		z-index: 3;
	}

	&__avatar-submenu-name {
		border-bottom: 1px solid $borderPrimaryColorOpacity20;
		font-size: 15px;
		line-height: 46px;
		letter-spacing: -0.23px;
		color: $textPrimaryOpacity60;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__messages-preloader {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 56px;
		left: 0;
		right: 0;
		pointer-events: none;
		z-index: 1;

		&--down {
			top: 106px;
		}

		svg {
			width: 36px;
			height: 36px;
		}
	}

	&__scrollbtns {
		display: flex;
		align-items: flex-start;
		position: absolute;
		bottom: 16px;
		right: 12px;
		z-index: 2;
	}

	&__mention {
		display: flex;
		align-items: center;
		width: 24px;
		height: 24px;
		margin-top: 2px;
		margin-right: 6px;
		padding: 0;
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23DBA742'/%3E%3Cpath d='M12.3079 20C13.471 20 14.5852 19.8465 15.4258 19.5779V18.2446C14.8393 18.5036 13.6078 18.6667 12.3763 18.6667C8.20281 18.6667 5.52474 16.0863 5.52474 12.0671V12.048C5.52474 8.11511 8.23213 5.32374 12.0342 5.32374C15.8754 5.32374 18.4753 7.65468 18.4753 11.1175V11.1367C18.4753 13.3429 17.7422 14.7722 16.5889 14.7722C15.9243 14.7722 15.5528 14.3789 15.5528 13.7074V8.25899H13.8913V9.41966H13.7251C13.3439 8.57554 12.474 8.06715 11.4478 8.06715C9.45388 8.06715 8.08552 9.67866 8.08552 12V12.0192C8.08552 14.446 9.44411 16.0767 11.4771 16.0767C12.6206 16.0767 13.471 15.5492 13.8815 14.5707H14.0476L14.0574 14.6283C14.2431 15.5492 15.113 16.1439 16.2663 16.1439C18.5535 16.1439 20 14.1871 20 11.0887V11.0695C20 6.89688 16.7844 4 12.1417 4C7.31338 4 4 7.2518 4 11.9904V12.0096C4 16.8729 7.24496 20 12.3079 20ZM11.7899 14.5803C10.6072 14.5803 9.88393 13.6211 9.88393 12.0671V12.048C9.88393 10.4748 10.5974 9.53477 11.8094 9.53477C13.0312 9.53477 13.8131 10.5132 13.8131 12.048V12.0671C13.8131 13.6019 13.0312 14.5803 11.7899 14.5803Z' fill='black'/%3E%3C/svg%3E%0A")
				no-repeat center,
			$primary;
		border-radius: 50%;
		user-select: none;
	}

	&__unreaded-messages-text {
		width: 28px;
		height: 28px;
		text-align: center;
		color: $textSecond;
		font-size: 15px;
		line-height: 28px;
		font-weight: 500;
		background-color: $primary;
		border-radius: 50%;
		margin-bottom: 5px;
	}

	&__unreaded-messages {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 2;
	}

	&__unreaded-messages-btn {
		width: 28px;
		height: 28px;
		font-size: 0;
		color: transparent;
		background: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.9999' cy='14' r='8.90909' fill='%23171717'/%3E%3Cpath d='M14 -1.11305e-05C6.34269 -1.04611e-05 -1.41769e-06 6.32913 -7.47082e-07 14C-7.76585e-08 21.6573 6.34269 28 14.0136 28C21.6709 28 28 21.6573 28 14C28 6.32913 21.6573 -1.17999e-05 14 -1.11305e-05ZM13.9864 6.88479C14.7183 6.88479 15.2469 7.38624 15.2469 8.10454L15.2469 14.7318L15.1113 17.6321L16.3853 16.06L17.9574 14.4201C18.1607 14.1897 18.4724 14.0542 18.8112 14.0542C19.4753 14.0542 19.9632 14.5285 19.9632 15.1926C19.9632 15.5314 19.8683 15.8025 19.6379 16.0465L14.9758 20.7493C14.6641 21.0745 14.3659 21.2236 13.9864 21.2236C13.5934 21.2236 13.2953 21.0745 12.9835 20.7493L8.33495 16.0465C8.10455 15.8025 7.99613 15.5314 7.99613 15.1926C7.99613 14.5285 8.49758 14.0542 9.16166 14.0542C9.50048 14.0542 9.79864 14.1762 10.0155 14.4201L11.5741 16.0465L12.8616 17.6457L12.726 14.7318L12.726 8.10454C12.726 7.38624 13.2546 6.88479 13.9864 6.88479Z' fill='%23DBA742'/%3E%3C/svg%3E%0A")
			no-repeat center;
	}

	&__mention,
	&__unreaded-messages-text,
	&__unreaded-messages-btn {
		-webkit-filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))
			drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
	}

	&__textarea-container {
		position: relative;
		width: 100%;
	}

	&__textarea-wrap-btn {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		height: 100%;
		margin-left: 8px;
	}

	&__textarea-stickers-emoji-btn {
		position: absolute;
		width: 24px;
		height: 24px;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		&--upper {
			top: auto;
			bottom: 28px;
			transform: none;
		}

		* {
			pointer-events: none;
		}
	}

	&__textarea-send-btn,
	&__textarea-timer-btn {
		display: block;
		width: 36px;
		height: 36px;
		flex-shrink: 0;
		border: none;
		border-radius: 18px;
	}

	&__textarea-send-btn {
		margin-left: 8px;
		color: transparent;
		font-size: 0;
		background: #000;

		svg {
			pointer-events: none;
			transform: rotate(-90deg);
			transition: fill 0.4s;
		}

		&[disabled] {
			pointer-events: none;

			svg {
				fill: rgba(255, 255, 255, 0.4);
			}
		}
	}

	&__textarea-timer-btn {
		color: $textPrimaryOpacity40;
		text-align: center;
		font-weight: 500;
		font-size: 12px;
		line-height: 36px;
		letter-spacing: -0.4px;
		font-feature-settings: 'tnum';
		font-variant-numeric: tabular-nums;
		background: #2c2c2e;
	}

	&__intro {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;

		.react-loading-skeleton {
			--base-color: #3e4042;
			--highlight-color: #333539;
		}

		#{$block}__intro-message-body {
			background-color: #333539;
		}
	}

	&__intro-messages {
		width: 256px;
		margin-bottom: 14px;
	}

	&__intro-message {
		display: flex;
		align-items: flex-end;
		margin-bottom: 8px;

		&:first-child {
			max-width: 197px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__intro-message-avatar {
		width: 32px;
		height: 32px;
		margin-right: 8px;
		border-radius: 16px;

		span {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__intro-message-body {
		width: calc(100% - 40px);
		padding: 8px;
		background-color: #1f2022;
		border-radius: 8px;

		> span {
			br {
				display: none;
			}
		}

		.react-loading-skeleton {
			display: block;
			max-width: 179px;
			height: 8px;
			margin-top: 8px;
			border-radius: 8px;

			&:first-child {
				margin-top: 0;
				width: 49px;
			}
		}
	}

	&__intro-content {
		text-align: center;
		padding-left: 12px;
		padding-right: 12px;
	}

	&__intro-title,
	&__intro-text {
		max-width: 316px;
		font-size: 14px;
		line-height: 24px;
		color: $textPrimaryOpacity40;
	}

	&__intro-title {
		font-weight: 700;
	}

	&__text-length {
		position: absolute;
		right: 13px;
		bottom: 7px;
		color: $textPrimaryOpacity20;
		font-size: 12px;
		line-height: 20px;
		font-weight: 400;
		transition: color $duration;
	}
}

@keyframes blink {
	0%,
	100% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}
}
