@import 'assets/styles/_imports';

.bet {

	&__heading {
		display: flex;
		justify-content: space-between;
		padding: 8px 0 0;
	}

	&__title {
		font-size: 14px;
		line-height: 20px;
		font-weight: 700;
		// color: $textSecond;
	}

	&__total-factor {
		font-size: 12px;
		font-weight: 500;

		// color: $textSecond;
		b {
			color: $textPrimary;
		}
	}

	&__events {
		position: relative;
		margin-bottom: 8px;
	}

	&__events-btn {
		position: absolute;
		z-index: 2;
		bottom: 5px;
		width: 100%;
		height: 40px;
		padding: 12px 0;
		color: $primary;
		font-size: 13px;
		line-height: 16px;
		font-weight: 500;
		background-color: rgb(88, 88, 88, .96);
	}

	&__event {
		padding: 8px 0;

		&:not(:last-of-type) {
			border-bottom: 1px solid #F0F0F0;
		}
	}

	&__event-title {
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		// color: $textSecondary;
		margin-bottom: 0;
	}

	&__event-outcome {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__event-outcome-title {
		font-size: 13px;
		line-height: 16px;
		font-weight: 500;
		// color: $textPrimary;
		margin-bottom: 0;
	}

	&__event-outcome-factor {
		font-size: 13px;
		line-height: 16px;
		font-weight: 700;
		color: $textPrimary;
		margin-left: 20px;
	}

	&__status {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;

		&--win {
			color: $primary;
		}

		&--lose {
			// color: $textSecond;
		}

		&--null {
			color: $blue;
		}
	}

	&__bottom {
		.btn {
			width: 100%;
		}
	}
}
