@import 'assets/styles/_imports';

.chat-bot-rules {
	display: flex;
	width: 100%;
	height: 100%;

	&__container {
		width: 100%;
		max-width: 294px;
		margin: auto;
		padding: 20px 16px;
		background: $backgroundSecond;
		box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
	}

	&__title {
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
		letter-spacing: -0.31px;
		color: $textPrimary;
	}

	&__items {
		counter-reset: chatBotRules;
		margin-bottom: 16px;
	}

	&__item {
		position: relative;
		margin-bottom: 16px;
		padding-left: 40px;

		&:last-child {
			margin-bottom: 0;
		}

		&:before {
			display: block;
			counter-increment: chatBotRules;
			content: counter(chatBotRules);
			width: 32px;
			height: 32px;
			position: absolute;
			top: 0;
			left: 0;
			font-weight: 500;
			font-size: 18px;
			line-height: 32px;
			text-align: center;
			color: $textPrimary;
			background: $backgroundInverseOpacity10;
			border-radius: 22px;
		}
	}

	&__item-title {
		font-size: 15px;
		line-height: 20px;
		letter-spacing: -0.23px;
		color: $textPrimary;
	}

	&__item-text {
		font-size: 13px;
		line-height: 18px;
		color: $textPrimaryOpacity60;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__close {
		display: block;
		padding: 0;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		letter-spacing: -0.31px;
		color: $primary;
	}
}